/* eslint-disable @typescript-eslint/indent */
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { Dispatch } from 'redux'
import { useTranslation } from 'react-i18next'
import track, { useTracking } from 'react-tracking'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { Button, Switch } from '@material-ui/core'

import InviteHeader from '../Invite/InviteHeader/InviteHeader'
import Highlight from '../Highlight/Highlight'
import InviteForm from '../Invite/InviteForm/InviteForm'
import {
  selectConfig,
  selectWelcomeScreenConfig,
  selectWelcomeStepsConfigs
} from '../../config/selectors'
import { RoutePath } from '../../core/routes/route-path'
import { agreeTerms, getUserInfo } from '../../user/actions'
import { selectUser } from '../../user/selectors'
import { UserStatus } from '../../user/userStatus'
import { IdList } from '../../core/utils/id-list'
import { AnalyticsCategory } from '../../core/analytics/analyticsCategory'
import BaseDrawer from '../BaseDrawer/BaseDrawer'
import { RedemptionCenterCondition } from '../../offer/redemptionCenterCondition'
import LoadingDialog from '../LoadingDialog/LoadingDialog'
import { toggleSignupWelcome, changeTab } from '../../config/actions'
import { TabsName } from '../../core/tabsName'
import history from '../../core/history'
import ticketIconImg from '../../../assets/images/highlight/ticketicon.png'
import './TermsConditions.scss'
import * as TC_CONSTANTS from './TermsConditionsConstant'
import _ from 'lodash'
import { useLocation } from 'react-router-dom'
import { EMPTY_PROMOTION_CODE_PARAM, PROMOTION_CODE_PARAM } from '../../constants'
const TermsConditions: FC = () => {
  const dispatch: Dispatch<ConsentAction> = useDispatch()
  const location = useLocation()
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const config: ConfigState = useSelector(selectConfig)
  const user: UserState = useSelector(selectUser)
  const welcomeScreenConfig: WelcomeScreenConfig = useSelector(
    selectWelcomeScreenConfig
  )
  const welcomeStepsConfigs: WelcomeStepsConfig[] = useSelector(
    selectWelcomeStepsConfigs
  )
  const [promoCode, setPromoValue] = useState('')
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(false)
  const [isSpinner, setIsSpinner] = useState(false)
  const [stepHeight, setStepHeight] = useState<number>(0)
  const [labelName, setLabelName] = useState<string>(t`Continue`)
  const [moreInfoLink, setMoreInfoLink] = useState(
    TC_CONSTANTS.baseWelcomeScreen.MoreInformationLinkURL
  )
  const [WelcomeSteps, setWelcomeSteps] = useState([
    {
      StepTitle: t`Step1Title`,
      StepDescription: t`Step1Description`,
      StepImage: TC_CONSTANTS.baseWelcomeSteps[0].StepImage
    },
    {
      StepTitle: t`Step2Title`,
      StepDescription: t`Step2Description`,
      StepImage: TC_CONSTANTS.baseWelcomeSteps[1].StepImage
    },
    {
      StepTitle: t`Step3Title`,
      StepDescription: t`Step3Description`,
      StepImage: TC_CONSTANTS.baseWelcomeSteps[2].StepImage
    }
  ])
  const [TermsAndConditionsURL, setTermsAndConditionsURL] = useState(
    TC_CONSTANTS.baseWelcomeScreen.MoreInformationLinkURL
  )
  const [PrivacyPolicyURL, setPrivacyPolicyURL] = useState(
    TC_CONSTANTS.baseWelcomeScreen.PrivacyPolicyURL
  )
  let maxHeight = 0
  let rowHeight = 0

  useEffect(() => {
    if (welcomeScreenConfig.IsInviteCodeDisplay) {
      const params = new URLSearchParams(location.search)
      const promotionCode = params.get(PROMOTION_CODE_PARAM)
      if (
        promotionCode != null &&
        promotionCode !== EMPTY_PROMOTION_CODE_PARAM
      ) {
        setPromoValue(promotionCode)
        if (!welcomeScreenConfig.IsInviteCodeMandatory) {
          setIsSwitchChecked(true)
        }
      }
    }
  }, [location, welcomeScreenConfig])

  useEffect(() => {
    if (welcomeScreenConfig != null) {
      if (worthToChange(welcomeScreenConfig.WelcomeButtonLabel)) {
        setLabelName(welcomeScreenConfig.WelcomeButtonLabel ?? '')
      }
      if (worthToChange(welcomeScreenConfig.MoreInformationLinkURL)) {
        setMoreInfoLink(welcomeScreenConfig.MoreInformationLinkURL)
      }
      if (worthToChange(welcomeScreenConfig.TermsAndConditionsURL)) {
        setTermsAndConditionsURL(welcomeScreenConfig.TermsAndConditionsURL)
      }
      if (worthToChange(welcomeScreenConfig.PrivacyPolicyURL)) {
        setPrivacyPolicyURL(welcomeScreenConfig.PrivacyPolicyURL)
      }
    }
  }, [welcomeScreenConfig])

  useEffect(() => {
    if (welcomeStepsConfigs != null && welcomeStepsConfigs.length > 0) {
      setWelcomeSteps(welcomeStepsConfigs)
    }
  }, [welcomeStepsConfigs])

  useEffect(() => {
    trackEvent({
      page: AnalyticsCategory.Policy,
      action: 'Agreement Policy Page View'
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    getStepHeight()
    setStepHeight(maxHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    resizeStep(stepHeight)
  }, [stepHeight])

  useEffect((): void => {
    if (user.userStatus !== UserStatus.New) {
      history.push(RoutePath.Home)
    }
  }, [user])

  const worthToChange = (obj: any): boolean => {
    if (obj != null && obj !== '') {
      return obj
    }
    return false
  }

  const handleAgreeTerms = async (event: any = null): Promise<void> => {
    setIsSpinner(true)
    trackEvent({
      page: AnalyticsCategory.Policy,
      action: 'Agree to Terms'
    })
    if (event !== null) {
      event.preventDefault()
    }
    if (
      config.SignUpWelcomeTitle != null &&
      config.SignUpWelcomeDescription != null
    ) {
      if (config.SignUpWelcomeButtonLabel != null) {
        dispatch(toggleSignupWelcome(true))
      }
    }

    return dispatch(agreeTerms())
      .then(() => dispatch(getUserInfo()))
      .then(() => history.push(RoutePath.Home))
      .catch((error: Error) => {
        setIsSpinner(false)
        Sentry.captureException(error)
      })
  }

  const handleInviteFormAfterSubmit = async (): Promise<void> => {
    trackEvent({
      action: 'Agree to Terms',
      page: AnalyticsCategory.Policy
    })
    trackEvent({
      action: 'Input Invite Code',
      page: 'Invite Code',
      payload: {
        origin: 't&c'
      }
    })

    history.replace(RoutePath.Home)
    return dispatch(changeTab(TabsName.Earn))
  }

  const handleSwitch = (event: ChangeEvent<HTMLInputElement>): void => {
    setIsSwitchChecked(event.target.checked)
  }

  const getStepHeight = (): void => {
    const steps = document.getElementsByClassName(
      'highlight-header__base'
    ) as HTMLCollectionOf<HTMLElement>

    if (!_.isNil(steps)) {
      for (let row = 0; row < steps.length; row++) {
        rowHeight = steps[row].offsetHeight
        if (rowHeight > maxHeight) {
          maxHeight = rowHeight
        }
      }
    }
  }
  const resizeStep = (stepHeight: number): void => {
    const steps = document.getElementsByClassName(
      'highlight-header__base'
    ) as HTMLCollectionOf<HTMLElement>
    const finalHeight = stepHeight.toString()
    for (let row = 0; row < steps.length; row++) {
      steps[row].style.height = finalHeight + 'px'
    }
  }
  const renderHighLights = (): any => {
    return WelcomeSteps.map((step, index) => {
      return (
        <Highlight
          key={index}
          title={step.StepTitle}
          description={step.StepDescription}
          image={step.StepImage}
          isLast={WelcomeSteps.length === index + 1}
        />
      )
    })
  }
  return (
    <div className='terms'>
      {welcomeScreenConfig != null ? (
        <>
          <InviteHeader
            title={welcomeScreenConfig.InviteCodeTitle}
            descriptionMarkdown={welcomeScreenConfig.InviteCodeDescription}
            configImg={welcomeScreenConfig.SignUpScreenImage ?? ''}
          />
          {renderHighLights()}
          {welcomeScreenConfig.IsInviteCodeDisplay &&
            !welcomeScreenConfig.IsInviteCodeMandatory && (
              <div className='terms__switcher'>
                <div className='terms__switcher__icon'>
                  <img
                    src={ticketIconImg}
                    className='terms__switcher__iconimg'
                    alt='terms icon'
                  />
                </div>
                <div className='terms__switcher__text'>
                  <p className='terms__switcher__title'>{t`haveInvite`}</p>
                  <p className='terms__switcher__description'>{t`haveInviteDescription`}</p>
                </div>
                <div className='terms__switcher__toggle'>
                  <Switch
                    className='switcher--default'
                    onChange={handleSwitch}
                    checked={isSwitchChecked}
                  />
                </div>
              </div>
            )}

          {welcomeScreenConfig.IsInviteCodeDisplay &&
          (welcomeScreenConfig.IsInviteCodeMandatory || isSwitchChecked) ? (
            <div>
              <InviteForm
                btnTxt={labelName}
                label={t`EnterInviteCodeStrict`}
                handleSubmit={handleInviteFormAfterSubmit}
                promotionCode={promoCode}
              />
            </div>
          ) : (
            <div className='terms__btn-container'>
              <Button
                id={IdList.InviteCodeLink}
                className='btn'
                onClick={handleAgreeTerms}
                onTouchEnd={handleAgreeTerms}
              >
                {labelName}
              </Button>
            </div>
          )}

          <div className='terms__links'>
            <span>{t`ByTappingOn`}&nbsp;</span>
            <span>{labelName}&nbsp;</span>
            <span>{t`YouAgreeTo`}&nbsp;</span>
            <a
              href={TermsAndConditionsURL}
              target='_blank'
              className='default-link'
              rel='noreferrer'
            >
              {t`Terms&Conditions`}&nbsp;
            </a>
            <span>{t`and`}&nbsp;</span>
            <a
              href={PrivacyPolicyURL}
              target='_blank'
              className='default-link'
              rel='noreferrer'
            >
              {t`PrivacyPolicy`}
            </a>
          </div>
          <div className='terms__links'>
            <a
              href={moreInfoLink}
              target='_blank'
              className='default-link'
              rel='noreferrer'
            >
              {welcomeScreenConfig.MoreInformationLink}
            </a>
            <span>{welcomeScreenConfig.MoreInformationLinkDescription}</span>
          </div>
        </>
      ) : null}

      <BaseDrawer
        isOpen={isSpinner}
        onClose={() => setIsSpinner(false)}
        isCentered
        centerCondition={RedemptionCenterCondition.both}
      >
        <LoadingDialog isSpinner={isSpinner} />
      </BaseDrawer>
    </div>
  )
}

export default track({
  page: AnalyticsCategory.Policy
})(TermsConditions)
