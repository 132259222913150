import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux'
import * as Sentry from '@sentry/react'
import { AxiosResponse } from 'axios'
import { Button, TextField } from '@material-ui/core'
import { useTracking } from 'react-tracking'
import BaseDrawer from '../../../ui/BaseDrawer/BaseDrawer'
import { IdentifierType } from '../../identifierType'
import { getProviderStatusList, setProviderStatus } from '../../actions'
import { isEmail } from '../../../core/utils/validation'
import { getEarningList, getUserInfo } from '../../../user/actions'
import { getCampaignStatuses } from '../../../campaign/actions'
import { providerLabelTemplate } from '../../../core/utils/providerLabelTemplate'
import { ConnectionType } from '../../connectionType'
import { selectUser } from '../../../user/selectors'

import './ProviderDialog.scss'
import { selectProviderRedirectURL } from '../../selectors'
import { useHistory } from 'react-router-dom'
import { RoutePath } from '../../../core/routes/route-path'
import { changeTab } from '../../../config/actions'
import { TabsName } from '../../../core/tabsName'
import MDEditor from '@uiw/react-md-editor'

interface ConnectDialogProps {
  isConnectDialogOpen: boolean
  setIsConnectDialogOpen: (flag: boolean) => void
  provider: ProviderAccount
  handleSubmitStart: () => void
  handleSubmit: (providerStatus?: ProviderStatus) => void
  identifier: Nullable<string>
  isFromProviderItem?: boolean
}

const ProviderDialog: FC<ConnectDialogProps> = ({
  isConnectDialogOpen,
  setIsConnectDialogOpen,
  provider,
  handleSubmitStart,
  handleSubmit,
  identifier,
  isFromProviderItem = false
}) => {
  const { t } = useTranslation()
  const { trackEvent } = useTracking()
  const dispatch = useDispatch()
  const history = useHistory()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const user: UserState = useSelector(selectUser)
  const providerRedirect: ProviderRedirectURL | null = useSelector(selectProviderRedirectURL)

  const form = useFormik({
    initialValues: {
      identifier: identifier ?? ''
    },
    enableReinitialize: true,
    onSubmit: values => {
      const identifier = values.identifier.toString()

      if (identifier !== '' && form.isValid) {
        trackEvent({
          action: 'Provide app credentials',
          page: 'Connect Apps',
          payload: {
            appID: provider.ProviderID
          }
        })
        handleSubmitStart()
        return dispatch(setProviderStatus(provider.ProviderID, identifier))
          .then(async (data: ProviderStatusAction) => {
            setErrorMessage('')
            trackEvent({
              action: 'Connect - App Connected',
              page: 'Connect Apps',
              payload: {
                appID: provider.ProviderID
              }
            })
            await Promise.all([
              dispatch(getUserInfo()),
              dispatch(getEarningList()),
              dispatch(getCampaignStatuses()),
              dispatch(getProviderStatusList())
            ])
            return handleSubmit(data.payload)
          })
          .catch((error: AxiosResponse) => {
            setErrorMessage(error.data.error_description)
            Sentry.captureException(new Error(error.data.error_description), {
              tags: {
                method: 'useForm-ProviderDialog'
              }
            })
            trackEvent({
              action: 'Connect - Connect Error',
              page: 'Connect Apps',
              payload: {
                errorCode: error.data.error
              }
            })
            handleSubmit()
          })
      }
    },
    validate: values => {
      let errors
      if (provider.identifierType === IdentifierType.NUMERIC_ID) {
        if (values.identifier === '') {
          errors = {
            identifier: 'Please enter a valid ID'
          }
        }
      } else if (provider.identifierType === IdentifierType.EMAIL) {
        if (isEmail(values.identifier)) {
          errors = {
            identifier: 'Please enter a valid Email'
          }
        }
      }
      return errors
    }
  })

  useEffect((): void => {
    form.validateForm()
      .then(
        () => {},
        () => {}
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identifier])

  const submitTheForm = async (event: any): Promise<any> => {
    event.preventDefault()
    if (provider.connectionType === ConnectionType.directOauth) {
      if (provider.IsPKCEFlow) {
        window.open(providerRedirect?.redirectionURL)
        setIsConnectDialogOpen(false)
        history.push(RoutePath.Home)
        dispatch(changeTab(TabsName.Earn))
      } else {
        if (provider.connectionUrl != null && provider.connectionUrl !== '') {
          const regexLabel = new RegExp('{{UserID}}', 'gi')
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          const targetURL = provider.connectionUrl.replace(regexLabel, `${user.UserID}`)
          window.open(targetURL)
        }
      }
    } else {
      await form.submitForm()
    }
  }

  const invalidForm =
    provider.connectionType !== ConnectionType.directOauth && !form.isValid
  const invalidPKCEURL =
    provider.connectionType === ConnectionType.directOauth &&
    provider.IsPKCEFlow &&
    (providerRedirect == null || providerRedirect.redirectionURL == null)

  return (
    <BaseDrawer
      isOpen={isConnectDialogOpen}
      onClose={() => setIsConnectDialogOpen(false)}
      title={t`ConnectAccount`}
      isFromDesktopTransactionHistory={isFromProviderItem}
    >
      <div className='connect-drawer'>
        <div className='connect-drawer__content'>
          <div className='connect-drawer__content'>
            <div style={{ backgroundImage: `url(${provider.logoImageUrl})` }} className='connect-drawer__content__img' />

            <div className='connect-drawer__content__description'>
              {provider.translations?.connectTitle != null &&
                <div className='connect-drawer__content__description__title'>{providerLabelTemplate(provider.Label, provider.translations.connectTitle)}</div>}
              <div className='connect-drawer__content__description__subtitle'>
                <MDEditor.Markdown
                  source={provider.connectDescription}
                  style={{ whiteSpace: 'pre-wrap' }}
                />
              </div>
            </div>
          </div>
        </div>
        <form className='connect-drawer__form-control'>
          {
            provider.connectionType !== ConnectionType.directOauth && (
              <>
                <label className='connect-drawer__form-control__label'>
                  {provider.identifierType === IdentifierType.EMAIL
                    ? t`Email`
                    : t`CardNumber`}
                </label>
                <TextField
                  name='identifier'
                  type={
                    provider.identifierType === IdentifierType.EMAIL
                      ? 'email'
                      : 'text'
                  }
                  value={form.values.identifier}
                  onChange={form.handleChange}
                  className='connect-drawer__form-control__field form-control'
                  variant='outlined'
                  error={errorMessage !== ''}
                  helperText={errorMessage}
                />
              </>
            )
          }
          <Button
            className='btn connect-drawer__form-control__btn-active'
            type='submit'
            classes={{ disabled: 'btn-disable' }}
            disabled={invalidForm || invalidPKCEURL}
            onClick={submitTheForm}
            onTouchEnd={submitTheForm}
          >
            {t`Connect`}
          </Button>
        </form>
      </div>
    </BaseDrawer>
  )
}

export default ProviderDialog
